@import "./components/layout/styles/normalize.scss";
@import "~semantic-ui-css/semantic.min.css";
@import "~antd/dist/antd.css";

.ui {
  &.segment, &.container {
    margin: 2rem auto;
    max-width: 1200px;
  }
  &.button {
    margin: auto 0.1em;
  }
}

.slider-wrapper {
  display: flex;
  justify-content: space-between;
  margin: auto;
  color: darkgrey
}

.slider-wrapper .ant-slider {
  width: 90%
}

p, li {
  font-size: 1.1em;
}

h2 {
  font-size: 2.2em;
}